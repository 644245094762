import messagesPL from './translations/pl.json'
import polishLocaleData from 'react-intl/locale-data/pl';
import { addLocaleData } from 'react-intl';
import 'moment/locale/pl';
import moment from 'moment'

export const configureLocale = () => {
  // TODO determine required locales and load all of them here
  moment.locale('pl')
  addLocaleData(polishLocaleData)

  type LocaleData = {
      [key: string]: any
  }

  const messagesData: LocaleData = {
      pl: messagesPL
  }

  const language =
    (navigator.languages && navigator.languages[0]) ||
    navigator.language;

  // Split locales with a region code
  const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];

  const messages =
    messagesData[languageWithoutRegionCode] ||
    messagesData[language] ||
    messagesData["pl"];

  return {
    language,
    messages
  }
}
